/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Box from '@material-ui/core/Box';
import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import sidekickInit from '../../utils/sidekick/init';
import styles from './ModuleText.module.scss';
import ElementLink from '../ElementLink';
import Image from '../Image';
import RichTextParser from '../RichTextParser';
import ErrorBoundary from '../ErrorBoundary';
import accents from '../../utils/accents';
import TradeMark from '../../generics/TradeMark';
import useLockedOffsetTop from '../ModuleHorizontal/useLockedOffsetTop';
import { ModuleTextPropTypes, CategoryPropTypes } from './ModuleTextPropTypes';

const variants = {
  center: {
    show: {
      y: 0,
      opacity: 1,
      transition: {
        y: { velocity: -100 }
      }
    },
    hidden: {
      y: 50,
      opacity: 0
    }
  },
  left: {
    show: {
      x: 0,
      opacity: 1,
      transition: {
        y: { velocity: -100 }
      }
    },
    hidden: {
      x: 50,
      opacity: 0
    }
  }
};

const checkBody = (obj) => !!obj?.json?.content?.length;

const displayIcon = (icon, sidekicker) =>
  icon && (
    <div className={styles.iconWrap}>
      <Image
        testId="ModuleText-icon"
        className={styles.icon}
        image={icon}
        {...sidekicker('Icon')}
      />
    </div>
  );

const displaySupertitle = (supertitle, sidekicker) =>
  supertitle && (
    <h3
      data-testid="ModuleText-supertitle"
      className={cx('h3', styles.supertitle, [{ accents: accents(supertitle) }])}
      {...sidekicker('Supertitle')}>
      <TradeMark>{supertitle}</TradeMark>
    </h3>
  );

const getTitleTag = (titleTag) => {
  let tag = null;
  switch (titleTag) {
    case 'h1':
      tag = 'h1';
      break;
    case 'h2':
      tag = 'h2';
      break;
    case 'h3':
      tag = 'h3';
      break;
    case 'h4':
      tag = 'h4';
      break;
    default:
      break;
  }
  return tag;
};

const displayTitle = (title, titleTag, supertitle, textVariant, lang, sidekicker) => {
  if (!title || title?.trim() === '') return null;
  const CustomTag = getTitleTag(titleTag);
  const trademark = <TradeMark>{title && title.split('\\n').join('\n')}</TradeMark>;
  const titleProps = {
    'variants': textVariant,
    'data-testid': 'ModuleText-title',
    'className': cx(
      'ModuleText-title',
      styles.title,
      lang === 'cn' && styles.titleCn,
      lang === 'hk' && styles.titleHk,
      [{ accents: accents(title) }]
    ),
    ...sidekicker('Title')
  };

  const customTitle = CustomTag && <CustomTag {...titleProps}>{trademark}</CustomTag>;

  const defaultTitle =
    title && supertitle ? (
      <h2 {...titleProps}>{trademark}</h2>
    ) : (
      <h2 {...titleProps}>{trademark}</h2>
    );

  return customTitle || defaultTitle;
};

const displayBody = (body, bodySize, textVariant, sidekicker, richTextProps, listItemVariant) =>
  checkBody(body) ? (
    <div
      variants={textVariant}
      data-testid="ModuleText-body"
      className={cx('ModuleText-body', styles.richText, styles[bodySize])}
      {...sidekicker('Body')}>
      <RichTextParser document={body} {...richTextProps} listItemVariant={listItemVariant} />
    </div>
  ) : (
    body &&
    typeof body === 'string' &&
    body.trim() !== '' && (
      <p
        data-testid="ModuleText-body"
        className={cx('ModuleText-body pb-4', styles[bodySize])}
        {...sidekicker('Body')}>
        <TradeMark>{body}</TradeMark>
      </p>
    )
  );

const displayCategories = (categories, sidekicker) =>
  categories?.length > 0 ? (
    <Box data-testid="ModuleText-categories" display="flex" pt={1} {...sidekicker('Categories')}>
      {categories.map((category) => (
        <Box key={category._id} display="flex" alignItems="center" pr={3}>
          {category.icon && (
            <Box className={styles.iconWrapper} width="25px" height="25px" pr={1}>
              <Image
                testId="ModuleText-category-icon"
                className={styles.icon}
                image={category.icon}
                {...sidekicker('Icon')}
              />
            </Box>
          )}
          {category.name && (
            <span
              data-testid="ModuleText-category-name"
              className={cx(styles.categoryName)}
              {...sidekicker('Name')}>
              {category.name}
            </span>
          )}
        </Box>
      ))}
    </Box>
  ) : null;

const ModuleText = React.forwardRef(function ModuleText(
  {
    className,
    _id,
    _contentTypeId,
    internalTitle,
    textJustification,
    title,
    titleTag,
    supertitle,
    body,
    bodySize,
    link,
    links,
    icon,
    anchor,
    backgroundColor,
    padding,
    categories,
    listItemVariant,
    mobileImage,
    variant,
    mobileDisclaimer
  },
  ref
) {
  const { lang } = useTranslation();
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });
  const { rightRef } = useLockedOffsetTop();

  const ctas =
    links &&
    links.map((item, idx) => (
      <div
        className={cx(styles.linkItem, 'mt-3 mb-1', {
          'mr-4': links.length > 1 && idx !== links.length - 1
        })}
        key={item.linkText || item.internalTitle}>
        <ElementLink
          style={item.style}
          href={item.href}
          as={item.as}
          linkText={item.linkText}
          icon={item.icon}
          iconString={item.iconString}
          target={item.target}
          isModal={item.isModal}
          download={item.download}
          trackingId={item.trackingId}
          {...sidekickInit(item).sidekicker('Element Link')}
        />
      </div>
    ));
  const textVariant = variants[textJustification];
  return (
    <ErrorBoundary>
      <Box
        data-testid="ModuleText"
        p={padding !== null ? padding : undefined}
        style={{ backgroundColor }}
        id={anchor?.toLowerCase()}
        className={cx(
          styles.moduleText,
          styles[textJustification],
          !body && 'pb-3',
          'ModuleText',
          className
        )}
        {...(_contentTypeId === 'bannerFullSize' ? {} : sidekicker('Module Text'))}>
        <div className="container" ref={ref}>
          <div className="row">
            <div className="col-12">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                transition={{ type: 'spring' }}
                variants={{
                  show: {
                    transition: { staggerChildren: 0.3 }
                  },
                  hidden: {
                    transition: { staggerChildren: 0.3, staggerDirection: -1 }
                  }
                }}
                className={textJustification === 'center' && 'container-lg'}>
                {displayIcon(icon, sidekicker)}
                {displaySupertitle(supertitle, sidekicker)}
                {displayTitle(title, titleTag, supertitle, textVariant, lang, sidekicker)}
                {displayCategories(categories, sidekicker)}
                {displayBody(
                  body,
                  bodySize,
                  textVariant,
                  sidekicker,
                  { _id, _contentTypeId, internalTitle },
                  listItemVariant
                )}
                {mobileImage && (
                  <div
                    style={variant === 'module-horizontal' ? { order: 2 } : undefined}
                    className={cx(
                      'ModuleHorizontal-image',
                      styles.mobileImage,
                      'content-image mobile'
                    )}>
                    <div
                      className="position-relative"
                      transition={{
                        type: 'spring',
                        damping: 100,
                        stiffness: 100,
                        mass: 2
                      }}
                      ref={rightRef}>
                      <div className={styles.shim} />
                      <Image
                        testId="ModuleHorizontal-image"
                        image={mobileImage}
                        columns={6}
                        {...sidekicker('Image')}
                      />
                    </div>
                    {mobileDisclaimer ? (
                      <div className={cx(styles.disclaimer)}>
                        <RichTextParser document={mobileDisclaimer} />
                      </div>
                    ) : null}
                  </div>
                )}
                {link && (
                  <div
                    style={variant === 'module-horizontal' ? { order: 1 } : undefined}
                    variants={textVariant}
                    className="ModuleText-Link mb-4 pb-5 pb-lg-0">
                    <ElementLink {...link} {...sidekickInit(link).sidekicker('Link')} />
                  </div>
                )}
                {links && (
                  <div
                    className={cx(
                      styles.links,
                      { 'd-flex align-items-center': links.length > 1 },
                      textJustification === 'center' && 'justify-content-center',
                      textJustification === 'left' && 'justify-content-start',
                      textJustification === 'right' && 'justify-content-end'
                    )}
                    {...sidekicker('Links')}>
                    {ctas}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </ErrorBoundary>
  );
});

ModuleText.propTypes = ModuleTextPropTypes;

displayCategories.propTypes = CategoryPropTypes;

displayCategories.defaultProps = {
  name: null,
  icon: null,
  listItemVariant: 'check'
};

ModuleText.defaultProps = {
  supertitle: null,
  body: null,
  bodySize: null,
  link: null,
  links: null,
  icon: null,
  anchor: '',
  titleTag: '',
  backgroundColor: 'transparent',
  padding: 0,
  categories: [],
  className: null,
  listItemVariant: 'check',
  mobileImage: null,
  mobileDisclaimer: null
};

export default ModuleText;
