/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import cx from 'classnames';

import ModuleText from '../../../ModuleText';
import ModuleFormPropTypes from '../../ModuleFormPropTypes';
import { useDynamicForm, FieldsInline } from '../../../../DynamicForm';
import sidekickInit from '../../../../utils/sidekick/init';

import styles from './FormInline.module.scss';

function FormInline({
  _id,
  _contentTypeId,
  internalTitle,
  formSettings,
  handleSubmit,
  submitButtonLabel,
  headerText,
  children,
  isDisabled
}) {
  const formRef = useRef(null);
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });

  const dynamicForm = useDynamicForm(formSettings.steps, { inline: true });
  const { form } = dynamicForm;

  return (
    <div className={styles.formInline} ref={formRef}>
      <div className={cx(styles.headerText, 'px-0 px-lg-4')}>
        {headerText && (
          <div data-testid="FormInline-headerText">
            <ModuleText {...headerText} />
          </div>
        )}
      </div>
      <div className="container-xl px-0 px-xl-5 pt-4 pb-0">
        <form
          data-testid="ModuleForm-form"
          onSubmit={form.handleSubmit(handleSubmit({ form_location: 'Inline' }))}
          {...sidekicker('Form Settings')}
        >
          <fieldset>
            <FieldsInline dynamicForm={dynamicForm} className={styles.field} />
            {/* Inject reCaptcha V2 */}
            {children}
          </fieldset>
          <div>
            <div className={cx(styles.btnWrap, 'pt-4 text-center')}>
              <button
                data-testid="ModuleForm-submit"
                className="btn btn-outline-black"
                type="submit"
                disabled={!form.formState.isValid || isDisabled}
                {...sidekicker('Submit Button Label')}
              >
                {submitButtonLabel}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

FormInline.propTypes = ModuleFormPropTypes;

FormInline.defaultProps = {
  headerText: null
};

export default FormInline;
