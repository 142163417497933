/**
 * This file meant as a simple way to add Inline-Forms
 * to your custom React component.
 *
 * Inline-Forms are meant to display all steps, one after the other
 * without any stepping process between them.
 *
 * For an example of this you could check https://impossiblefoods.com/sellimpossible
 * (bottom of the page)
 *
 * DynamicForm prop comes from the useDynamicForm hook
 * <FieldsInline dynamicForm={dynamicForm} />
 * */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FieldOfType from './componentRegistry';
import { generateFieldsForStep } from './dependsOn';
import { DynamicForm } from './PropTypes';

const StepWrapper = styled.div`
  @media (min-width: 1024px) {
    fieldset {
      grid-template-columns: repeat(2, calc(50% - 56px));
    }
  }
`;

// we might want to toggle off the numbering
// eslint-disable-next-line no-unused-vars
function DefaultHeader({ step, index, stepCount }) {
  if (!step.title) return null;

  return (
    <legend>
      <span>{index + 1}</span>
      <span>{step.title}</span>
    </legend>
  );
}

DefaultHeader.propTypes = {
  index: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  step: PropTypes.shape({ title: PropTypes.string }).isRequired
};

function FieldsInline({ dynamicForm, header, className, children, sectionBgColor }) {
  const { form, steps } = dynamicForm;
  return (
    <>
      {steps
        // Steps can be enabled or disabled based on formState
        // and all steps will display at the same time
        .map((step, index) => {
          const { title } = step;
          const fields = generateFieldsForStep(steps[index], form.getValues());

          return (
            <>
              {header && header({ step, index, stepCount: steps.length })}
              <StepWrapper key={`${title}-${fields[0].id}`}>
                <fieldset>
                  {fields.map((field) => {
                    const id = `${field.id}-inline`;
                    return (
                      <FieldOfType
                        key={field.id || field.name}
                        field={{ ...field, id }}
                        form={form}
                        sectionBgColor={sectionBgColor}
                        className={className}
                      />
                    );
                  })}
                </fieldset>
              </StepWrapper>
            </>
          );
        })}
      {children}
    </>
  );
}

FieldsInline.propTypes = {
  children: PropTypes.node,
  header: PropTypes.func,
  dynamicForm: DynamicForm.isRequired,
  className: PropTypes.string,
  sectionBgColor: PropTypes.string
};

FieldsInline.defaultProps = {
  header: DefaultHeader,
  className: '',
  children: null,
  sectionBgColor: null
};

export default FieldsInline;
