/** global window */

import React from 'react';
import throttle from 'lodash/throttle';

export const HEADER_HEIGHT = 90;
export const MIN_HEIGHT_DIFF = 200;

function useLockedOffsetTop() {
  const [top, setTop] = React.useState();
  const rightRef = React.useRef();
  const leftRef = React.useRef();
  React.useEffect(() => {
    const MIN_TOP = 0;
    const onScroll = throttle(() => {
      if (rightRef.current && leftRef.current) {
        const leftRect = leftRef.current.getBoundingClientRect();
        if (
          leftRef.current.scrollHeight - MIN_HEIGHT_DIFF >= rightRef.current.scrollHeight &&
          window.innerWidth > 700
        ) {
          const maxOffsetTop = Math.max(
            MIN_TOP,
            leftRef.current.scrollHeight - rightRef.current.scrollHeight
          );
          const offsetTop = Math.max(
            MIN_TOP,
            Math.min(maxOffsetTop, -leftRect.top + HEADER_HEIGHT)
          );
          setTop(Math.min(maxOffsetTop, offsetTop ?? 0));
        } else if (top !== 0) {
          setTop(0);
        }
      }
    }, 100);

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    top,
    leftRef,
    rightRef
  };
}

export default useLockedOffsetTop;
